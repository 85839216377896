@import 'variables.scss';

.row{
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    position: relative;
    -webkit-flex-wrap: wrap;
    flex-wrap: wrap;
    &.row__max{
        max-width: 1200px;
        margin: auto;
    }
    &.row__max__blog{
        max-width: 1200px;
        margin: auto;
    }
}

@mixin flex($flexnumber) {
    width: #{$flexnumber};
}


.padding___left{
    padding-left: 20px;
    @include responsive(ipad){
        padding-left: 15px;
    }
    @include responsive(iphonePlus) {
        padding-left: 12px;
    }
}
.padding___right{
    padding-right: 20px;
    @include responsive(ipad){
        padding-right: 15px;
    }
    @include responsive(iphonePlus) {
        padding-right: 12px;
    }
}

.no___padding___left{
    padding-left: 0px;
}

.no___padding___right{
    padding-right: 0px;
}

$colValue : 8.3333%;

.col___gb{
    @include flex($colValue);
    box-sizing: border-box;
    &.col___gb2{
        @include flex($colValue * 2);
    }
    &.col___gb3{
        @include flex($colValue * 3);
    }
    &.col___gb4{
        @include flex($colValue * 4);
    }
    &.col___gb5{
        @include flex($colValue * 5);
    }
    &.col___gb6{
        @include flex($colValue * 6);
    }
    &.col___gb7{
        @include flex($colValue * 7);
    }
    &.col___gb8{
        @include flex($colValue * 8);
    }
    &.col___gb9{
        @include flex($colValue * 9);
    }
    &.col___gb10{
        @include flex($colValue * 10);
    }
    &.col___gb11{
        @include flex($colValue * 11);
    }
    &.col___gb12{
        @include flex($colValue * 12);
    }
    &.col___gb13{
        @include flex($colValue * 13);
    }
    &.col___gb14{
        @include flex($colValue * 14);
    }
    &.col___gb15{
        @include flex($colValue * 15);
    }
    &.col___gb16{
        @include flex($colValue * 16);
    }
}
