@font-face {
	font-family: 'GTSectraFineTrial-Regular';
	src: url('../fonts/GTSectraFineTrial-Regular.eot');
	src: url('../fonts/GTSectraFineTrial-Regular.eot?#iefix') format('embedded-opentype'),
		url('../fonts/GTSectraFineTrial-Regular.woff') format('woff'),
		url('../fonts/GTSectraFineTrial-Regular.ttf') format('truetype');
	font-weight: normal;
	font-style: normal;
}

@font-face {
	font-family: 'GTSectraFineTrial-Bold';
	src: url('../fonts/GTSectraFineTrial-Bold.eot');
	src: url('../fonts/GTSectraFineTrial-Bold.eot?#iefix') format('embedded-opentype'),
		url('../fonts/GTSectraFineTrial-Bold.woff') format('woff'),
		url('../fonts/GTSectraFineTrial-Bold.ttf') format('truetype');
	font-weight: normal;
	font-style: normal;
}

@font-face {
	font-family: 'GTSectraTrialBlack-Italic';
	src: url('../fonts/GTSectraTrialBlack-Italic.eot');
	src: url('../fonts/GTSectraTrialBlack-Italic.eot?#iefix') format('embedded-opentype'),
		url('../fonts/GTSectraTrialBlack-Italic.woff') format('woff'),
		url('../fonts/GTSectraTrialBlack-Italic.ttf') format('truetype');
	font-weight: normal;
	font-style: normal;
}
