@import 'variables.scss';

body {
  background-color: $grey;

  ::selection {
    background: $black;
    color: $grey;
  }
  ::-moz-selection {
    background: $black;
    color: $grey;
  }
}



.home {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  min-height: 95vh;

  .menu {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: baseline;

    .brand {
      a {
        color: $black;
        text-decoration: none;
      }
    }
    .submenu {
      a {
        color: $black;
        text-decoration: none;

        &:hover {
          text-decoration: underline;
        }
      }
    }
  }

  .lng {
    cursor: pointer;

    a {
      color: $black;
      text-decoration: none;

      &:hover {
        text-decoration: underline;
      }
    }
  }

  .links {
    a {
      color: $black;
      text-decoration: none;

      &:hover {
        text-decoration: underline;
      }
    }
  }
}

.home_orange {
  background-color: $orange;
  h2, h3, h4, a {
    color: $black !important;
  }

  ::selection {
    background: $black;
    color: $orange;
  }
  ::-moz-selection {
    background: $black;
    color: $orange;
  }
}

.home_green {
  background-color: $green;

  h2, h3, h4, a {
    color: $grey !important;
  }

  ::selection {
    background: $grey;
    color: $green;
  }
  ::-moz-selection {
    background: $grey;
    color: $green;
  }
}

.home_black {
  background-color: $black;
  h2, h3, h4, a {
    color: $grey !important;
  }

  ::selection {
    background: $grey;
    color: $black;
  }
  ::-moz-selection {
    background: $grey;
    color: $black;
  }
}
