@import 'variables.scss';

label {
  font-family: $typeface-regular;
  font-size: 18px;
  letter-spacing: 3px;
  line-height: 27px;
  font-weight: normal;
  text-transform: uppercase;
}

input {
  font-family: $typeface-regular;
  font-size: 15px;
  font-weight: normal;
  letter-spacing: 1.6px;
}

h2 {
  font-family: $typeface-regular;
  font-weight: normal;
  font-size: 40px;
  color: $black;
  @include responsive(ipad){
      font-size: 35px;
  }
  @include responsive(iphone){
      font-size: 30px;
  }
}

h3 {
  font-family: $typeface-regular;
  font-weight: normal;
  font-size: 20px;
  color: $black;
}

h4 {
  font-family: $typeface-regular;
  font-size: 15px;
  font-weight: normal;
  color: $black;
}

h5 {
  font-family: $typeface-regular;
  font-size: 22px;
  letter-spacing: 0.5px;
  line-height: 27px;
  font-weight: normal;
}

h6 {
  font-family: $typeface-regular;
  font-size: 14px;
  font-weight: normal;
  letter-spacing: 2.5px
}

p {
  font-family: $typeface-regular;
  font-size: 18px;
  font-weight: normal;
  letter-spacing: 0.5px;
  line-height: 27px;
}
